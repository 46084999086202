<template>
  <div class="topbar-item">
    <div
      class="btn btn-icon w-auto text-primary btn-clean d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
    >
      <i class="mdi mdi-account menu-icon text-danger" style="color: gray !important;"></i>
      Hi 
      <span class="text-primary" style="text-transform:lowercase;">
        &nbsp;{{ displayName }}
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          User Profile
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative;"
      >
        <div class="d-flex align-items-center mt-5">
          <div class="d-flex flex-column">
            <router-link
              to="/custom-pages/profile"
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {{ displayName }}
            </router-link>
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/Communication/Mail-notification.svg"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                  <span class="navi-text text-muted text-hover-primary">
                    {{ displayEmail }}
                  </span>
                </span>
              </a>
            </div>
            <button v-if="isImpersonating" class="btn btn-light-danger btn-bold" @click="endImpersonation">
              Switch Back to Admin 
            </button>
            <button v-else class="btn bg-primary text-light btn-bold" @click="onLogout">
              Sign out
            </button>
          </div>
        </div>
        <div class="separator separator-dashed mt-8 mb-5"></div>
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { LOGOUT, END_IMPERSONATION } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";

export default {
  name: "KTQuickUser",
  data() {
    return {
      isStaff: false,
      staffInfo: null
    };
  },
  computed: {
    ...mapGetters([
      "currentUserPersonalInfo",
      "isImpersonating"
    ]),
    displayName() {
      // If impersonating, show the impersonated user's name
      if (this.isImpersonating) {
        return this.currentUserPersonalInfo.name;
      }
      // If staff, show staff name
      if (this.isStaff && this.staffInfo) {
        return this.staffInfo.name;
      }
      // Otherwise show regular user name
      return this.currentUserPersonalInfo.name;
    },
    displayEmail() {
      // If impersonating, show the impersonated user's email
      if (this.isImpersonating) {
        return this.currentUserPersonalInfo.email;
      }
      // If staff, show staff email
      if (this.isStaff && this.staffInfo) {
        return this.staffInfo.email;
      }
      // Otherwise show regular user email
      return this.currentUserPersonalInfo.email;
    }
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    endImpersonation() {
      this.$store
        .dispatch(END_IMPERSONATION)
        .then(() => {
          this.$store.state.isImpersonating = false;
          this.$forceUpdate();
          this.$router.push({ name: "businesses" });
          const closeButton = document.getElementById('kt_quick_user_close');
          closeButton.click();
        });
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    checkStaffStatus() {
      // Check session storage for staff status
      const isStaff = sessionStorage.getItem('is_staff');
      this.isStaff = isStaff === '1';
      if (this.isStaff) {
        try {
          const staffData = sessionStorage.getItem('staff');
          if (staffData) {
            this.staffInfo = JSON.parse(staffData);
          }
        } catch (e) {
          console.error('Error parsing staff data:', e);
        }
      }
    }
  },
  mounted() {
    this.checkStaffStatus();
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  }
};
</script>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>